/* The Modal (background) */
.lightbox-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.lightbox-modal.hide {
  visibility: hidden;
  opacity: 0;
}

/* Modal Content */
.modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

@media (min-width: 768px) {
  .modal-content {
    height: 90%;
    width: 90%;
  }
}

.lightbox-slides {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  flex: 1;
}

.lightbox-slide {
  text-align: center;
  position: absolute;
  opacity: 0;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lightbox-slide.active-slide {
  opacity: 1;
  transform: scale(1);
}

.lightbox-slide.prev-slide {
  transform: scale(0.5) translateX(-100%);
}

.lightbox-slide.next-slide {
  transform: scale(0.5) translateX(100%);
}

.lightbox-slide img {
  max-width: 100%;
}

.caption-container {
  color: #aaa;
  text-align: center;
  width: 100%;
  padding: 0 var(--padding-base);
  bottom: 0;
  transition: transform 0.5s, opacity 0.5s;
}

.lightbox-slide.prev-slide .caption-container {
  opacity: 0;
  transform: translateX(-50px);
}

.lightbox-slide.next-slide .caption-container {
  opacity: 0;
  transform: translateX(50px);
}

.lightbox-slide.active-slide .caption-container {
  opacity: 1;
  transform: translateX(0);
}

@media (min-width: 768px) {
  .caption-container {
    width: auto;
    height: auto;
  }
}

@media (max-width: 767px) {
  .lightbox-slide {
    top: 0;
    min-height: 100%;
    max-height: 100%;
  }
  .lightbox-slide picture {
    overflow: hidden;
  }
  .lightbox-slide img {
    max-height: 100%;
  }
  .lightbox-slide .caption-container {
    min-height: 55px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .lightbox-slides {
    min-height: 100%;
  }
  .lightbox-slide {
    display: block;
    height: 100%;
    width: 100%;
  }
  .lightbox-slide img {
    object-fit: contain;
    max-height: 100%;
  }
  .caption-container {
    display: none;
  }
}

@media (min-width: 768px) {
  .lightbox-slides {
    justify-content: center;
  }

  .lightbox-slide {
    max-height: 100%;
  }
  .lightbox-slide picture {
    display: block;
    overflow: hidden;
  }
  .lightbox-slide img {
    /*
     * 90% modal-content
     * thumbnail + image title = 138px
     */
    max-height: calc(90vh - 138px);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-base);
  height: 65px;
}

.close-button {
  --size: 25px;
  height: var(--size);
  width: var(--size);
  position: relative;
  display: inline-block;
  cursor: pointer;
}

@media (min-width: 768px) {
  .close-button {
    --size: 40px;
  }
}

.close-button::before,
.close-button::after {
  --thin: 2px;
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc(var(--thin) / -2);
  margin-left: calc(var(--size) / -2);
  display: block;
  height: var(--thin);
  width: var(--size);
  background-color: #aaa;
  transition: all 0.25s ease-out;
}

@media (min-width: 768px) {
  .close-button::before,
  .close-button::after {
    --thin: 3px;
  }
}

.close-button:after {
  transform: rotate(-135deg);
}

.close-button:hover::before,
.close-button:hover::after {
  transform: rotate(0deg);
  --size: 20px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .close {
    top: 10px;
    right: 10px;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 30px);
  width: auto;
  transform: translateY(-50%);
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #aaa;
  font-size: 12px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.numbertext {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-base);
  height: 65px;
}

@media (min-width: 768px) {
  .numbertext {
    top: 10px;
    left: 10px;
  }
}

.thumbnail-item a {
  display: block;
  opacity: 0.8;
  transition: 0.3s all;
  outline: none;
}

.active a,
.thumbnail-item a:hover,
.thumbnail-item a:focus {
  opacity: 1;
  transform: scale(1.05);
  backface-visibility: hidden;
}

.active {
  position: relative;
}

.thumbnail-item.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid var(--color-textlink);
}

.thumbnail-list {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  list-style: none;
  padding: 0 0 2px;
  margin: 0;
  max-height: 50px;
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .thumbnail-list {
    max-height: 75px;
    overflow: hidden;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.thumbnail-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.thumbnail-list {
  -ms-overflow-style: none;
}

/* Hide scrollbar for Firefox */
.thumbnail-list {
  scrollbar-width: none;
}

.thumbnail-item {
  flex: 0 0 16.667%;
  padding: 5px;
  align-self: center;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-height: 45px;
}

@media (min-width: 768px) {
  .thumbnail-item {
    max-height: 75px;
  }
}

@media (min-width: 500px) {
  .thumbnail-list {
    margin-top: 10px;
  }

  .thumbnail-item {
    flex: 0 0 10%;
    height: 100%;
  }
}

.thumbnail-item img {
  width: 100%;
}

.thumbnail-list:after {
  content: '';
  display: table;
  clear: both;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*----------*/
/* Fillpath */
/*----------*/

.nav-fillpath a {
  width: 30px;
  height: 30px;
}

@media (min-width: 768px) {
  .nav-fillpath a {
    width: 100px;
    height: 70px;
  }
}

.nav-fillpath .icon-wrap {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.nav-fillpath a::before,
.nav-fillpath a::after,
.nav-fillpath .icon-wrap::before,
.nav-fillpath .icon-wrap::after {
  position: absolute;
  left: 50%;
  width: 2px;
  height: 50%;
  background-color: #aaa;
  content: '';
  transition: transform 0.3s;
  backface-visibility: hidden;
}

.nav-fillpath .icon-wrap::before,
.nav-fillpath .icon-wrap::after {
  z-index: 100;
  height: 0;
  background: #fff;
  transition: height 0.3s, transform 0.3s;
}

.nav-fillpath a::before,
.nav-fillpath .icon-wrap::before {
  top: 50%;
  transform: translateX(-50%) rotate(-135deg);
  transform-origin: 50% 0%;
}

.nav-fillpath a.next::before,
.nav-fillpath a.next .icon-wrap::before {
  transform: translateX(-50%) rotate(135deg);
  transform-origin: 50% 0%;
}

.nav-fillpath a::after,
.nav-fillpath .icon-wrap::after {
  top: 50%;
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: 0 0;
}

.nav-fillpath a.next::after,
.nav-fillpath a.next .icon-wrap::after {
  transform: translateX(-50%) rotate(45deg);
  transform-origin: 100% 0%;
}

.nav-fillpath h3 {
  display: none;
}

@media (min-width: 768px) {
  .nav-fillpath h3 {
    display: block;
    position: absolute;
    top: 50%;
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.85rem;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    white-space: nowrap;
  }
}

.nav-fillpath a.prev h3 {
  left: 100%;
  transform: translateY(-50%) translateX(-50%);
}

.nav-fillpath a.next h3 {
  right: 100%;
  text-align: right;
  transform: translateY(-50%) translateX(50%);
}

@media (min-width: 768px) {
  .nav-fillpath a:hover .icon-wrap::before,
  .nav-fillpath a:hover .icon-wrap::after {
    height: 50%;
  }

  .nav-fillpath a:hover::before,
  .nav-fillpath a:hover .icon-wrap::before {
    transform: translateX(-50%) rotate(-125deg);
  }

  .nav-fillpath a.next:hover::before,
  .nav-fillpath a.next:hover .icon-wrap::before {
    transform: translateX(-50%) rotate(125deg);
  }

  .nav-fillpath a:hover::after,
  .nav-fillpath a:hover .icon-wrap::after {
    transform: translateX(-50%) rotate(-55deg);
  }

  .nav-fillpath a.next:hover::after,
  .nav-fillpath a.next:hover .icon-wrap::after {
    transform: translateX(-50%) rotate(55deg);
  }

  .nav-fillpath a:hover h3 {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
}

/*------------------*/
/* Full Screen Mode */
/*------------------*/

@media (max-width: 767px) and (orientation: landscape) {
  .nav-fillpath,
  .thumbnail-list,
  .close,
  .caption-container,
  .numbertext {
    opacity: 0;
    visibility: hidden;
  }
}
