:root {
  --color-highlight: var(--color-textlink);
  --color-input-fill: #fff;
  --color-input-text: var(--color-bg);
  --column-gap: 1.4em;
}

.content--contact form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--column-gap);
}

.content--contact-headline {
  color: var(--color-highlight);
  margin-bottom: 0;
}

.input {
  grid-column: span 2;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
}

@media (min-width: 768px) {
  .input {
    grid-column: span 1;
  }
}

.input.textarea {
  grid-column: span 2;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: var(--color-input-fill);
  font-weight: 400;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

textarea.input__field {
  height: 95px;
  width: 100%;
  resize: none;
  background: var(--color-input-fill);
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  padding: 0 1em;
  width: 40%;
  color: var(--background-input-text);
  font-weight: bold;
  font-size: 90%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.input--wave {
  overflow: hidden;
}

.input__field--wave {
  padding: 0.6em 0.5em;
  margin-top: var(--column-gap);
  width: 100%;
  background: transparent;
  color: var(--color-input-text);
  font-size: 1.2em;
  opacity: 0;
}

.input__label--wave {
  width: 100%;
  text-align: left;
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  padding: 0 0.25em;
  transform: translate3d(1em, 2.75em, 0);
  transition: transform 0.3s;
}

.input__label-content--wave {
  color: var(--color-input-text);
  padding: 0.25em 0;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.input__label-content--wave::after {
  content: attr(data-content);
  position: absolute;
  font-weight: 800;
  top: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  color: var(--color-highlight);
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.85em;
}

@media (min-width: 768px) {
  .input__label-content--wave::after {
    padding-bottom: 3px;
  }
}

.graphic--wave {
  fill: var(--color-input-fill);
  pointer-events: none;
  top: 1em;
  bottom: 0px;
  height: 4.5em;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.7s, fill 0.7s;
  transition: transform 0.7s, fill 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.textarea .graphic--wave {
  height: 7.5em;
}

.input__field--wave:focus,
.input--filled .input__field--wave {
  -webkit-transition: opacity 0s 0.35s;
  transition: opacity 0s 0.35s;
  opacity: 1;
}

.input__field--wave:focus + .input__label--wave,
.input--filled .input__label--wave {
  transition-delay: 0.15s;
  transform: translate3d(0, 0, 0);
}

.input__field--wave:focus + .input__label--wave .input__label-content--wave,
.input--filled .input__label-content--wave {
  transition-delay: 0.15s;
  transform: translate3d(0, -100%, 0);
}

.input__field--wave:focus ~ .graphic--wave,
.input--filled .graphic--wave {
  fill: #fff;
  transform: translate3d(-66.6%, 0, 0);
}

.submit-button {
  grid-column-start: 2;
  grid-column-end: 3;
  margin-top: var(--column-gap);
  text-align: right;
}

.submit-button button {
  background: var(--color-highlight);
  outline: none;
  border: 1px solid transparent;
  padding: 0.6em 1em;
  width: 150px;
  color: var(--color-bg);
  font-weight: 600;
  font-size: 15px;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .submit-button button:hover,
  .submit-button button:focus {
    background: var(--color-bg);
    border: 1px solid var(--color-alt);
    color: var(--color-alt);
  }
}

@media (max-width: 767px) {
  .input {
    max-width: 100%;
  }
  .input.textarea {
    max-width: 100%;
  }
  .submit-button {
    display: block;
    float: none;
    grid-column: span 2;
    margin-top: calc(2 * var(--column-gap));
  }
  .submit-button button {
    width: 100%;
    padding: 0.8em;
  }
  .footer {
    margin-top: var(--column-gap);
  }
}
